@import '~src/theme.scss';

$radio-width: 20px;

.ant-radio {
  &-group {
    display: flex;
  }
  &-wrapper {
    max-width: 50%;
    white-space: normal;
    display: flex;
    span.ant-radio + * {
      padding-right: 6px;
      padding-left: 6px;

      font-family: Roboto;
      color: $font-black-light;
      line-height: 20px;
    }

    span:first-child {
      height: $radio-width;
    }
  }

  &-inner {
    width: $radio-width;
    height: $radio-width;
    border-color: $font-black-light;
    &::after {
      top: -3px;
      left: -3px;
      width: 40px;
      height: 40px;
      border-radius: 50%;
    }
  }
  &-checked {
    .ant-radio-inner {
      border-color: $ver-light-blue;
      &::after {
        background-color: $ver-light-blue;
      }
    }
  }
}
