@import '~src/theme.scss';

.ant-dropdown {
  &.profile-dropdown {
    width: 707px;
    color: $font-base;
    margin: 0px;
    background-color: $white;
    border-bottom-left-radius: 6px;
    border-bottom-right-radius: 6px;
    top: 23px;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
    padding: 16px;

    @media screen and (max-width: $size-xs) {
      margin-right: 0px;
      left: 16px !important;
      width: 100vw;
    }

    .sub-title {
      font-size: 18px;
      font-weight: 400;
      line-height: 24px;
      text-transform: uppercase;
      padding-bottom: 19px;
      color: black;
    }

    .ant-btn {
      height: 40px;
      border-radius: 4px;
      font-size: 16px;
      line-height: 24px;
      padding: 7px;
      text-align: left;
      display: flex;
      font-weight: 800;
    }

    .notifications-section {
      .ant-btn {
        svg-icon {
          margin-right: 5px;
        }
      }
      .notification-rows {
        .thumbnail {
          width: 85px;
          height: 40px;
          border-radius: 8px;
          background-color: $primary-blue-30;
          .share-icon {
            width: 24px;
            height: 24px;
          }
        }
        .text {
          width: 403px;
          padding-left: 8px;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: pre;
          font-family: Roboto;
          > div {
            line-height: 22px;
            font-size: 16px;
          }
          .top {
            color: black;
          }
          .bottom {
            font-weight: bold;
          }
        }

        .confirm-btn {
          width: 81px;
        }

        .cancel-btn {
          width: 90px;

          background-color: $dark-grey;
          border-color: $dark-grey;
          margin-left: 16px;
        }
        .row {
          margin-bottom: 22px;
          &:last-child {
            margin-bottom: 19px;
          }
        }
      }
      border-bottom: 1px solid $light-grey;
    }

    .buttons-row {
      margin-top: 16px;
      .mgi-button {
        span.text {
          margin: 0 19px;
        }
        margin-top: 10px;
      }
    }
  }

  &.references-type-dropdown {
    .ant-dropdown-menu {
      padding: 0px;
      border: 1px solid $dark-grey;
      border-radius: 4px;
    }
    .ant-dropdown-menu-item {
      font-weight: 700;
      color: $dark-grey;
      background-color: $light-grey;
      font-size: 16px;
      padding: 7px;
      text-transform: uppercase;
      &.active {
        color: $ver-light-blue;
        background-color: $white;
      }

      &:first-child {
        border-top-left-radius: 4px;
        border-top-right-radius: 4px;
      }
      &:last-child {
        border-bottom-left-radius: 4px;
        border-bottom-right-radius: 4px;
      }
    }
  }
}

.cdk-overlay-dark-backdrop {
  background: white;
  opacity: 0.85;
}
