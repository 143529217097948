@import '~src/theme.scss';

$bg-color: white;
$fore-color: $mgi-dark-blue;

.ant-checkbox {
  display: inline-block;
  vertical-align: middle;
  &-input {
    display: none;
  }
  &-input {
    position: relative;
    z-index: -9999;
    opacity: 0;
  }
  &-inner {
    border: 1px solid $fore-color;
    width: 14px;
    height: 14px;
    vertical-align: middle;
    display: inline-block;
    border-radius: 11px;
  }
  &-checked &-inner {
    background-color: $bg-color;
    border: 1px solid $fore-color;
    position: relative;

    &:after {
      content: ' ';
      width: 6px;
      transform: rotate(40deg);
      position: absolute;
      height: 9px;
      top: 1px;
      border-bottom: 2px solid $fore-color;
      border-right: 2px solid $fore-color;
      border-color: $fore-color;
    }
  }
}
