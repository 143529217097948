@import './app/styles/styles.scss';
@import '~quill/dist/quill.core.css';
@import '~quill/dist/quill.bubble.css';
@import '~quill/dist/quill.snow.css';

@font-face {
  font-family: 'Open Sans';
  src: url('~src/assets/fonts/OpenSans-Regular.ttf');
}

@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  src: url('~src/assets/fonts/roboto-v29-latin-regular.eot'); /* IE9 Compat Modes */
  src: local(''),
    url('~src/assets/fonts/roboto-v29-latin-regular.eot?#iefix')
      format('embedded-opentype'),
    /* IE6-IE8 */ url('~src/assets/fonts/roboto-v29-latin-regular.woff2')
      format('woff2'),
    /* Super Modern Browsers */
      url('~src/assets/fonts/roboto-v29-latin-regular.woff') format('woff'),
    /* Modern Browsers */ url('~src/assets/fonts/roboto-v29-latin-regular.ttf')
      format('truetype'),
    /* Safari, Android, iOS */
      url('~src/assets/fonts/roboto-v29-latin-regular.svg#Roboto') format('svg'); /* Legacy iOS */
}
/* roboto-700 - latin */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 700;
  src: url('~src/assets/fonts/roboto-v29-latin-700.eot'); /* IE9 Compat Modes */
  src: local(''),
    url('~src/assets/fonts/roboto-v29-latin-700.eot?#iefix')
      format('embedded-opentype'),
    /* IE6-IE8 */ url('~src/assets/fonts/roboto-v29-latin-700.woff2')
      format('woff2'),
    /* Super Modern Browsers */
      url('~src/assets/fonts/roboto-v29-latin-700.woff') format('woff'),
    /* Modern Browsers */ url('~src/assets/fonts/roboto-v29-latin-700.ttf')
      format('truetype'),
    /* Safari, Android, iOS */
      url('~src/assets/fonts/roboto-v29-latin-700.svg#Roboto') format('svg'); /* Legacy iOS */
}

@font-face {
  font-family: 'SF UI Display';
  src: url('~src/assets/fonts/SF-UI-Display-Bold.otf');
}

@font-face {
  font-family: 'Selawik';
  src: url('~src/assets/fonts/Selawk.ttf');
}

@font-face {
  font-family: 'Montserrat';
  src: url('~src/assets/fonts/Montserrat/static/Montserrat-Regular.ttf');
  font-weight: normal;
}

@font-face {
  font-family: 'Montserrat';
  src: url('~src/assets/fonts/Montserrat/static/Montserrat-Light.ttf');
  font-weight: 300;
}

/*
@font-face {
  font-family: 'Montserrat';
  src: url('~src/assets/fonts/Montserrat/static/Montserrat-ExtraBold.ttf');
  font-weight: 800;
}
*/

@font-face {
  font-family: 'Poppins';
  src: url('~src/assets/fonts/Poppins/Poppins-Regular.ttf');
  font-weight: normal;
}

@font-face {
  font-family: 'Poppins';
  src: url('~src/assets/fonts/Poppins/Poppins-ExtraBold.ttf');
  font-weight: 800;
}

/* You can add global styles to this file, and also import other style files */
:root {
  --sat: env(safe-area-inset-top);
  --sar: env(safe-area-inset-right);
  --sab: env(safe-area-inset-bottom);
  --sal: env(safe-area-inset-left);
}

html,
body {
  height: 100%;
}

body * {
  margin: 0;
  font-family: 'Montserrat', 'Open Sans', sans-serif;
}

strong {
  font-weight: 800;
}

.ant-input-number-handler {
  display: none;
}

.ant-tooltip-inner {
  color: black;
  background-color: white;
  max-width: 350px;
  width: 350px;
}
