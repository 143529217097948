@import '~src/theme.scss';

.ant-form {
  &-item-label {
    text-align: left;
    width: 100%;
    > label {
      font-family: Roboto;
      color: $font-base;
      width: 100%;
      line-height: 20px;
      font-weight: 400;
      height: 20px;

      .optional-label {
        // text-align: right;
        // width: 100%;
        position: absolute;
        right: 0px;
        font-size: 12px;
        line-height: 16px;
        font-family: 'Open Sans';
        color: $font-black-light;
        text-transform: uppercase;
      }
    }
  }
  &-item {
    margin-bottom: 18px;
    align-content: start;
    input[type='radio'] {
      width: 20px;
      height: 20px;
    }
  }
  &-item-has-error .ant-input-prefix {
    color: inherit;
  }
}

.ant-input {
  &-affix-wrapper {
    height: 40px;
    border-radius: 4px;
    padding: 4px 8px;
    .svg-icon {
      height: 20px;
      width: 20px;
      svg {
        height: 20px;
        width: 20px;
        path {
          stroke: #808080;
        }
      }
      &.phone {
        display: flex;
        align-items: center;
        svg {
          height: 16px;
          width: 16px;
        }
      }
    }
    input {
      height: 30px;
      border: 0;
      width: 100%;
      outline: none;
      font-family: Roboto;
      color: black;
      &::placeholder {
        color: $font-black-light;
      }
    }
  }
  &-prefix {
    width: 20px;
  }

  &-group {
    textarea.ant-input {
      border-radius: 4px !important;
    }
  }
}
