@import 'antd/modal';
@import 'antd/checkbox';
@import 'antd/dropdown';
@import 'antd/layout';
@import 'antd/radiogroup';
@import 'antd/form';

@keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

.ui-loader-holder {
  background: white;
  opacity: 0.8;
  top: 60px;
  left: 0;
  right: 0;
  bottom: 0;
  position: absolute !important;
  display: flex;
  z-index: 2;

  .util-loader {
    width: 50px;
    height: 50px;
    //border: 1px solid rgba(0, 0, 0, 0.2);
    border-left-color: #333333;
    border: 3px solid transparent;
    border-radius: 50%;
    border-top-color: #333;
    margin: auto;
    position: relative;
    display: inline-block;
    vertical-align: top;
    //animation: loader-spin 1.2s infinite linear;
    -webkit-animation: spin 2s linear infinite;
    animation: spin 2s linear infinite;

    &:before {
      content: ' ';
      position: absolute;
      top: 3px;
      left: 3px;
      right: 3px;
      bottom: 3px;
      border-radius: 50%;
      border: 3px solid transparent;
      border-top-color: #666;
      -webkit-animation: spin 3s linear infinite;
      animation: spin 3s linear infinite;
    }
    &:after {
      content: ' ';
      position: absolute;
      top: 10px;
      left: 10px;
      right: 10px;
      bottom: 10px;
      border-radius: 50%;
      border: 3px solid transparent;
      border-top-color: #999;
      -webkit-animation: spin 1.5s linear infinite;
      animation: spin 1.5s linear infinite;
    }
    .util-loader-checkmark {
      display: none;
    }
    .util-loader-error {
      display: none;
    }

    i {
      font-size: 50px !important;
    }

    &.failure,
    &.success {
      -webkit-animation: none;
      animation: none;
      border: none;
      &:before {
        display: none;
      }
      &:after {
        display: none;
      }
    }
    &.success {
      .util-loader-checkmark {
        display: block;
        i {
          color: #4de515;
        }
      }
    }
    &.failure {
      .util-loader-error {
        display: block;
        i {
          color: #ec1c24;
        }
      }
    }
  }
}
