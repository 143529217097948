$mgi-primary: #004f7e;
$mgi-cool-grey: #53565a;
$mgi-light-blue: #85b6c5;
$mgi-new-light-blue: #529fcf;
$mgi-accent-plum: #8a2d5f;
$mgi-accent-wip: #c93797;
$mgi-lime-green: #6ee252;

$mgi-white: #fff;
$mgi-gray-0-5: #f1f1f1;
$mgi-gray-0-8: #f2f2f2;
$mgi-gray-1: #e5e5e5;
$mgi-gray-1-5: #e7e7e7;
$mgi-gray-2: #cccccc;
$mgi-gray-3: #b2b2b2;
$mgi-gray-4: #999999;
$mgi-gray-5: #7f7f7f;
$mgi-gray-6: #666666;
$mgi-gray-7: #4c4c4c;
$mgi-gray-8: #333333;
$mgi-gray-9: #191919;
$mgi-black: #000;

/////////////////
//
// DEPRECATED
//
////////////////
$white: #ffffff;
$mgi-orange: #f28f24;
$mgi-green: #24e630;

$mgi-grey: #f0f0f0;

$mgi-error: #f03738;

$mgi-dark-blue: #0e6f91;

$mgi-dark-blue-80: #0e6f9180;
$mgi-dark-blue-20: #0e6f9120;

$mgi-blue: #3eb2e8;

$mgi-greyblue: #688297;

$mgi-purple: #9827f5;

$mgi-red-purple: #c93797;

$mgi-profile-basa: #1d2139;

$primary-blue: #004f7e;
$primary-blue-30: #004f7e30;

$dashboard-side-padding-large: 32px;
$dashboard-side-padding-small: 32px;

$card-shadow: 1.4px 1.4px 1.4px 0 rgba(0, 0, 0, 0.4);

$dark-grey: #7f7f7f;

$light-grey: #e5e5e5;

$ver-light-blue: #1f8fd6;

$font-base: #4c4c4c;
$font-grey-2: #cccccc;
$font-grey-3: #828282;
$font-grey: #b2b2b2;
$font-grey-4: #999999;

$font-black-light: rgba(0, 0, 0, 0.5);

$ver-status-grey: #807588;
$ver-status-orange: #ff9425;

$ver-cool-grey: #53565a;
$ver-cool-grey-30: #53565a30;

$footer-height: 93px;

$size-xs: 599px;
$size-sm: 959px;
$size-md: 1279px;
$size-lg: 1919px;
$size-xl: 5000px;

$size-xs-height: 450px;
