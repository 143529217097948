@import '~src/theme.scss';

.ant-layout {
  &-header {
    @media screen and (max-width: 1000px) {
      padding: 0 70px;
    }
    @media screen and (max-width: 800px) {
      padding: 0 30px;
    }
    @media screen and (max-width: 600px) {
      padding: 0 10px;
    }
    @media screen and (max-width: 400px) {
      padding: 0 5px;
    }
  }

  &-content {
    @media screen and (max-width: $size-sm) {
      overflow-y: auto;
    }
  }
}
