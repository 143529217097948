@import '~src/theme.scss';

.ant-modal {
  &-header {
    background-size: cover;
    border-bottom: none;
    padding: 19px 24px;
  }

  &-body {
    background-color: white;
    padding: 34px 32px 16px;
    min-height: 200px;
    max-height: 700px;
    overflow-y: auto;
    border-radius: 8px;

    svg-icon.close-icon {
      cursor: pointer;
    }
  }

  &-footer {
    background-color: white;
    text-align: center;
    border-top: none;
    padding: 0px 32px 32px;
    border-radius: 8px;
  }

  &-title {
    font-size: 20px;
    text-align: center;
    color: white;
    font-style: italic;
    font-weight: bold;
  }

  &-content {
    border-radius: 8px;
  }

  &.user-profile-modal,
  &.settings-modal,
  &.terms-modal,
  &.share-modal {
    // @media screen and (min-width: 1280px) {
    //   width: 60% !important;
    //   max-width: 890px;
    // }
    // @media screen and (max-width: 1279px) {
    //   width: 75% !important;
    // }
    .ant-modal-content {
      .ant-checkbox-wrapper {
        span {
          color: $font-black-light;
          font-size: 14px;
          line-height: 20px;
          cursor: pointer;
          font-family: Roboto;
        }
      }

      .ant-checkbox {
        .ant-checkbox-inner {
          width: 18px;
          height: 18px;
          border-radius: 0px;
        }

        &.ant-checkbox-checked {
          .ant-checkbox-inner {
            background-color: $ver-light-blue;
            border: 1px solid white;

            &:after {
              width: 6px;
              height: 12px;
              top: 1px;
              left: 5px;
              border-bottom: 2px solid white;
              border-right: 2px solid white;
              border-color: white;
            }
          }
        }
      }
    }
  }

  &.share-modal,
  &.settings-modal {
    // max-width: 550px !important;
    .ant-modal-body {
      padding: 50px 37.6px 0px 37.6px;
    }
  }

  &.terms-modal {
    max-width: 448px !important;

    .ant-modal-body {
      padding: 32px;
    }
  }

  &.user-profile-modal {
    .sub-header {
      margin-top: 20px;
    }
  }

  &.survey-modal {
    width: 390px !important;
    max-height: 420px;

    .ant-modal-body {
      padding: 16px 22.5px 16px 22.5px;
    }

    .ant-modal-header,
    .ant-modal-content,
    .ant-modal-body,
    .ant-modal-footer {
      background-color: #372f3c;
    }

    .anticon-close {
      color: white;
    }
  }

  &.ref-view-modal {
    width: auto;
    max-width: inherit;
    padding: 0px;
    margin: 0px;
    top: 0px;
    height: 100%;

    .ant-modal-content {
      height: 100%;
    }

    .ant-modal-body {
      max-height: inherit;
      min-height: inherit;
      height: 100%;
      padding: 88px 60px 134px;
    }

    .ant-modal-content,
    .ant-modal-body,
    .ant-modal-footer {
      background-color: #372f3c;
    }

    @media only screen and (max-width: $size-xs) and (orientation: portrait) {
      .ant-modal-content &:not(.mgi-reference-view) {
        transform: rotate(-90deg);
        transform-origin: left top;
        width: 100vh;
        height: 100vw;
        overflow-x: hidden;
        position: absolute;
        top: 100%;
        left: 0;
      }
    }

    @media screen and (max-width: $size-sm) {
      .ant-modal-body {
        padding: 0px;
      }
    }
  }

  .row {
    display: flex;
    flex-direction: row;

    .flex-fill {
      flex: 1;

      &.left {
        margin-right: 8px;
      }

      &.right {
        margin-left: 8px;
      }
    }
  }

  .row-field {
    mgi-button {
      margin-left: 16px;
      margin-top: 22px;
      text-align: center;
    }
  }

  i.anticon {
    font-size: 16px;
    color: #808080;
  }

  .ant-btn-secondary i.anticon {
    color: white;
  }

  .errorMsg {
    padding-top: 10px;
    color: red;
  }

  @media screen and (max-width: $size-sm) {
    width: 100% !important;
    max-width: 100%;
    margin: 0px;
    padding: 0px;
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    display: flex;

    .ant-modal-content {
      flex: 1;
      display: flex;
      flex-direction: column;
      width: 100%;
      overflow-y: auto;

      .ant-modal-body {
        flex: 1;
      }
    }
  }

  @media screen and (max-width: $size-xs) {
    top: 0px;

    .ant-modal-body {
      max-height: inherit;
    }

    .row {
      flex-direction: column;

      .right,
      .left {
        margin: 0px !important;
      }
    }

    &-footer {
      .mgi-button {
        margin-top: 10px;
      }
    }
  }
}
